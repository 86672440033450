import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
    const [y, setY] = useState(window.scrollY);
    const [sidebarOpen, toggleSidebar] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => setY(window.scrollY));
        return () => {
            window.removeEventListener("scroll", () => setY(window.scrollY));
        };
    }, [y]);


    return (
        <>
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
            <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "75px" } : { height: "90px" }}>
                <NavInner className="container flexSpaceCenter">
                    <Link className="pointer flexNullCenter" to="home" smooth={true}>
                        <img src="assets/img/nova-logo.png" width="130px" alt="logo" />
                    </Link>
                    <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
                        <BurgerIcon />
                    </BurderWrapper>
                    <UlWrapper className="flexNullCenter" >
                        <li className="semiBold font15 pointer">
                            <Link activeClass="active" style={y > 100 ? { padding: "10px 15px" } : { padding: "15px 15px 18px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                                Ana Sayfa
                            </Link>
                        </li>
                        <li className="semiBold font15 pointer">
                            <Link activeClass="active" style={y > 100 ? { padding: "10px 15px" } : { padding: "15px 15px 18px 15px" }} to="about" spy={true} smooth={true} offset={-80}>
                                Hakkımızda
                            </Link>
                        </li>
                        <li className="semiBold font15 pointer">
                            <Link activeClass="active" style={y > 100 ? { padding: "10px 15px" } : { padding: "15px 15px 18px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                                Hizmetlerimiz
                            </Link>
                        </li>
                        <li className="semiBold font15 pointer">
                            <Link activeClass="active" style={y > 100 ? { padding: "10px 15px" } : { padding: "15px 15px 18px 15px" }} to="why" spy={true} smooth={true} offset={-80}>
                                Neden Nova Teknoloji Hizmetleri ?
                            </Link>
                        </li>
                        <li className="semiBold font15 pointer">
                            <Link activeClass="active" style={y > 100 ? { padding: "10px 15px" } : { padding: "15px 15px 18px 15px" }} to="contact" spy={true} smooth={true} offset={-80}>
                                İletişim
                            </Link>
                        </li>
                    </UlWrapper>
                    <UlWrapperRight className="flexNullCenter">
                    </UlWrapperRight>
                </NavInner>
            </Wrapper>
        </>
    );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


