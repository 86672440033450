import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/about-1.jpg";
import AddImage2 from "../../assets/img/about-2.jpg";
import AddImage3 from "../../assets/img/about-3.png";
import AddImage4 from "../../assets/img/about-4.jpg";
import WhoAreWe from "../../assets/img/who-are.jpg"

export default function About() {
  return (
    <Wrapper id="about">
      <div className="whiteBg" style={{ padding: "0" }}>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font14 semiBold">Nova Teknoloji Hizmetleri</h4>
                <h2 className="font40 extraBold">Hakkımızda</h2>
                <p className="font16">
                  <b>Nova Teknoloji Hizmetleri</b>, dijital dünyada işletmelerin ihtiyaç duyduğu bilişim hizmetlerini tek çatı altında sunan öncü bir firmadır. Yazılım geliştirme, veri analitiği, siber güvenlik ve IT altyapı yönetimi gibi birçok alanda sunduğumuz yenilikçi çözümlerle, müşterilerimizin iş süreçlerini daha verimli hale getiriyor ve dijital dönüşümlerine katkıda bulunuyoruz.
                </p>
                <p className="font16">
                  Teknolojik gelişmeleri yakından takip ederek hizmetlerimizi sürekli güncelliyor, güçlü altyapımız ve uzman kadromuzla müşterilerimize en kaliteli hizmeti sunmayı amaçlıyoruz. Sektörde güvenilir ve saygın bir firma olarak, iş ortaklarımıza değer katmaya ve başarı hikayelerinde yer almaya devam edeceğiz.
                </p>
                <ButtonsRow style={{ marginTop: "30px" }}>
                  <Link className="service-button" style={{ width: "210px" }} to={"services"} spy={true} smooth={true} offset={-80}>
                    <FullButton title="Hizmetleri Görüntüle" />
                  </Link>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
          <div className="container">
            <div className="row who-are-we-row">
              <div className="col-lg-6 col-md-6 who-are-we">
                <img src={WhoAreWe} alt="who-are-we" width="100%" />
              </div>
              <div className="col-lg-6 col-md-6">
                <h2 className="font40 extraBold">Biz Kimiz ?</h2>
                <p>
                  Biz, müşterilerimize yenilikçi ve güvenilir bilişim hizmetleri sunan bir teknoloji firmasıyız. Deneyimli ekibimizle, yazılım geliştirme, siber güvenlik, veri analitiği ve bulut çözümleri gibi geniş bir yelpazede hizmet veriyoruz. Amacımız, işletmelerin dijital dönüşüm süreçlerini hızlandırarak iş süreçlerini optimize etmelerine ve rekabet avantajı kazanmalarına yardımcı olmaktır.
                </p>
                <p>
                  Vizyonumuz, sektörde öncü ve güvenilir bir firma olarak tanınmak ve müşterilerimize sürdürülebilir başarılar getiren çözümler sunmaktır. Teknolojik gelişmeleri yakından takip ederek, hizmetlerimizi sürekli güncelliyor ve inovasyona verdiğimiz önemle iş ortaklarımızın güvenilir teknoloji ortağı olmaktan gurur duyuyoruz.
                </p>
              </div>
            </div>
          </div>
          <div className="container misyon-container" style={{ marginTop: "100px", paddingBottom: "75px" }}>
            <h2 className="font40 extraBold textCenter">Misyon ve Vizyonumuz</h2>
            <div className="row" style={{ marginTop: "50px" }}>
              <div className="col-lg-6 col-md-6">
                <h2 className="textCenter">Misyonumuz</h2>
                <p style={{ marginTop: "0" }}>
                  Müşterilerimize sağladığımız her hizmette, iş süreçlerini daha verimli hale getirmelerine ve dijital dönüşümlerini başarıyla tamamlamalarına katkıda bulunmayı amaçlıyoruz. Deneyimli ekibimizle birlikte, yenilikçi çözümler üreterek teknolojinin gücünü iş ortaklarımızın hizmetine sunuyoruz. Kalite standartlarımızdan ödün vermeden, müşteri memnuniyetini her zaman en üst seviyede tutarak sektördeki liderliğimizi sürdürmeyi hedefliyoruz.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <h2 className="textCenter">Vizyonumuz</h2>
                <p style={{ marginTop: "0" }}>
                  Vizyonumuz, teknolojinin hızla değişen dinamiklerine uyum sağlayarak müşterilerimize sürdürülebilir başarılar getiren çözümler sunmak ve sektörde öncü bir konuma gelmektir. Yenilikçi düşünce yapımızla, her geçen gün büyüyen ve gelişen bir firma olarak tanınmak ve güvenilir bir teknoloji ortağı olarak müşterilerimizin tercihi olmak için çalışıyoruz. Gelecekte de inovasyona ve müşteri odaklı yaklaşımımıza sadık kalarak, dijital dünyadaki değişimleri liderlikle yönlendirmeyi sürdüreceğiz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;